import React, { useEffect, useState } from 'react'
import Container from '../components/container'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Search from '../components/hub/search/search'
import { graphql, Link } from 'gatsby'

import * as styles from '../components/globals-v2.module.css'
import * as start from '../pages/content-hub/start.module.css'
import Category from '../components/hub/category/category'
import Card from '../components/hub/card/card'
import * as linkStyle from '../styles/components/links.module.css'
// import Icon from '../components/icons'

export const query = graphql`
    query SearchTemplateQuery {
        written: allSanityWritten(sort: {order: DESC, fields: publishedAt}) {
            nodes {
                _id
                _type
                tags {
                    title
                    slug {
                        current
                    }
                }
                openLink
                title
                blurb
                slug {
                    current
                }
                featureImage {
                    asset {
                        url
                        _id
                    }
                }
            }
        }
        audio: allSanityAudio(sort: {order: DESC, fields: publishedAt}) {
            nodes {
                _id
                _type
                tags {
                    title
                    slug {
                        current
                    }
                }
                featureImage {
                    asset {
                        _id
                        url
                    }
                }
                series {
                    title
                    slug {
                        current
                    }
                }
                title
                slug {
                    current
                }
                appleUrl
                spotifyUrl
            }
        }
        video: allSanityVideo(sort: {order: DESC, fields: publishedAt}) {
            nodes {
                _id
                _type
                tags {
                    title
                    slug {
                        current
                    }
                }
                title
                slug {
                    current
                }
                series {
                    title
                    slug {
                        current
                    }
                }
                featureImage {
                    asset {
                        _id
                        url
                    }
                }
            }
        }
        tags: allSanityTag(filter: { display: { ne: false } }) {
            nodes {
                display
                title
                position
                slug {
                    current
                }
            }
        }
    }
`

const SearchTemplate = ({ data, location }) => {
  const perPage = 4

  const [allPosts, setAllPosts] = useState([])
  const [results, setResults] = useState([])
  const [resultCount, setResultCount] = useState(perPage)

  const query = new URLSearchParams(location.search).get('query')
  const allSanityData = [...data.written.nodes, ...data.audio.nodes, ...data.video.nodes]

  useEffect(() => {
    setResults([])
    function getSearchResult () {
      if (!query) return []

      const searchTerm = new RegExp(query, 'i')

      const filterByTitleOrTags = (node) => {
        const matchesTitleOrTag = searchTerm.test(node.title) || node.tags?.some(tag => searchTerm.test(tag.title))
        const isNotExcludedSeries = !['cartoon-avatars', 'web3-after-dark'].includes(node.series?.slug?.current)
        const isNotDraft = !node._id.startsWith('drafts.')

        return matchesTitleOrTag && (node.series ? isNotExcludedSeries : true) && isNotDraft
      }

      return allSanityData.filter(filterByTitleOrTags)
    }

    const allPosts = getSearchResult()
    if (!allPosts.length) return

    setAllPosts(allPosts)
    setResults(allPosts.slice(0, resultCount))
  }, [resultCount, query, data])

  return (
    <Layout hideContactBar hideAnnouncements logoOverrideUrl='/content-hub/' version='1'>

      <SEO title={`Search Result: ${query}`} />

      <Container size='large' class={styles.container}>
        <section className={start.menuWtSearch}>
          <Category nodes={data.tags.nodes} type='category' />
          <Search />
        </section>

        <section className={styles.tagHead}>
          <Link to='/content-hub/' className={`${linkStyle.linkArrow} ${styles.linkArrow} ${linkStyle.back}`}>
            Back
          </Link>
          <div className={styles.tags}>
            <span className={styles.tag} style={{ height: 'auto' }}>
              {query}
            </span>
          </div>
        </section>
        {results?.length > 0 ? (
          <section className={`${start.twoColumnsGrid} ${start.separator}`}>
            <div className={`${start.sectionWrapper} ${start.recentWrapper}`}>
              {results.map((article, index) => (
                <Card key={index} section='mostRecent' type='simple' options={{ tags: true }} data={article} />
              ))}
            </div>
            {(resultCount < allPosts?.length) && (
              <div className={start.moreButton}>
                <a className={linkStyle.linkPlus} onClick={() => setResultCount(resultCount + perPage)}>More
                  <span>
                    <div className={linkStyle.iconPlus} />
                  </span>
                </a>
              </div>
            )}
          </section>
        ) : <div className={styles.noResults}>No results found</div>}
      </Container>
    </Layout>
  )
}

export default SearchTemplate
